import {
  CharterOrdersParams,
  CharterOrdersResponse,
} from 'api/order/fetchOrdersByCharterId';
import updateOrderStatus from 'api/order/updateOrderStatus';
import updateCharterStatusInOrder from 'views/Order/OrdersTable/utils/updateCharterStatusInOrder';
import useCharter from 'hooks/useCharter/useCharter';
import useQueryKey from 'hooks/useQueryKey/useQueryKey';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { CharterOrder } from 'types/order';
import { ORDER, ORDERS, ORDERS_PENDING_COUNT } from 'utils/constants/queryKeys';

export type PendingOrderStatus = {
  order: CharterOrder;
  action: 'approve' | 'decline';
};

export default function useOrderStatus(
  searchParams?: CharterOrdersParams,
  page?: number
) {
  const { t } = useTranslation();
  const { charter } = useCharter();
  const queryClient = useQueryClient();

  const [pendingStatus, setPendingStatus] = useState<PendingOrderStatus | null>(
    null
  );

  // invalidate order queries or update only cached data for specific page
  const invalidate = !page;

  const orderQueryKey = useQueryKey([ORDER, charter?.id]);
  const ordersQueryKey = useQueryKey(
    invalidate
      ? [ORDERS, charter?.id]
      : [ORDERS, charter?.id, searchParams, page]
  );

  const mutation = useMutation(updateOrderStatus, {
    onSuccess: (data, variables) => {
      setPendingStatus(null);

      if (invalidate) {
        queryClient.invalidateQueries(orderQueryKey);
        queryClient.invalidateQueries(ordersQueryKey);
      } else {
        queryClient.setQueryData<CharterOrdersResponse | undefined>(
          ordersQueryKey,
          (oldOrders) => {
            if (oldOrders) {
              return updateCharterStatusInOrder(oldOrders, data, variables);
            }
          }
        );
      }

      queryClient.invalidateQueries([ORDERS_PENDING_COUNT]);
    },
    onError() {
      toast.error(t('Something went wrong. Please try again.'));
    },
  });

  const changeOrderStatus = useCallback(
    (message: string) => {
      if (!pendingStatus) {
        return;
      }

      mutation.mutate({
        charterId: charter?.id,
        orderId: pendingStatus.order.id,
        status: pendingStatus.action === 'approve' ? 'APPROVED' : 'DECLINED',
        message: message,
      });
    },
    [charter?.id, mutation, pendingStatus]
  );

  return useMemo(
    () => ({ changeOrderStatus, pendingStatus, setPendingStatus }),
    [changeOrderStatus, pendingStatus, setPendingStatus]
  );
}
