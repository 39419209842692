import classNames from 'classnames';
import Badge from 'components/Badge/Badge';
import { NavLink } from 'components/routing';
import styles from './PrimaryNavItem.module.scss';

export type PrimaryNavItemProps = {
  name: string;
  to: string;
  countLabel?: number;
  exact: boolean;
};

export default function PrimaryNavItem({
  name,
  to,
  countLabel,
  exact,
}: PrimaryNavItemProps) {
  return (
    <li className="relative">
      <NavLink
        to={to}
        exact={exact}
        activeClassName={classNames('font-bold', styles.active)}
        className="block pl-4 py-4 no-underline text-gray-600 text-lg"
      >
        <span className="relative z-10">
          {!!countLabel ? (
            <Badge
              color="red"
              value={countLabel}
              max={999}
              className="-top-3 ml-1"
            >
              {name}
            </Badge>
          ) : (
            <span>{name}</span>
          )}
        </span>
      </NavLink>
    </li>
  );
}
