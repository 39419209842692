import { CharterBoatList } from 'api/fleet/fetchBoatsByCharterId';
import escapeString from 'utils/string/escapeString';

export default function filterByTitle(boats: CharterBoatList, title: string) {
  if (!title.trim().length) {
    return boats;
  }

  const escapedTitle = escapeString(title);
  const regex = new RegExp(escapedTitle, 'i');
  return boats.filter((boat) => regex.test(boat.title));
}
