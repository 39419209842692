import { CharterBoatList } from 'api/fleet/fetchBoatsByCharterId';

export default function filterByCabins(
  boats: CharterBoatList,
  cabins: [number, number]
) {
  return boats.filter(
    (boat) => boat.cabins >= cabins[0] && boat.cabins <= cabins[1]
  );
}
