import fetchOrdersCountByCharterId from 'api/order/fetchOrdersCountByCharterId';
import useCharter from 'hooks/useCharter/useCharter';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ORDERS_PENDING_COUNT } from 'utils/constants/queryKeys';

export default function useSidebarCount() {
  const { charter } = useCharter();

  const { data: ordersCountData } = useQuery(
    [ORDERS_PENDING_COUNT, charter?.id],
    () => {
      if (!charter) {
        return;
      }
      return fetchOrdersCountByCharterId(charter.id, 'PENDING');
    },
    { refetchInterval: 15_000 }
  );

  return useMemo(
    () => ({
      ordersCount: ordersCountData?.count || 0,
    }),
    [ordersCountData?.count]
  );
}
