import { useMemo, useReducer } from 'react';
import {
  ordersFiltersInitialState,
  ordersFiltersReducer,
} from 'reducers/filters/orders';

export default function useOrdersFilters() {
  const [filters, dispatchFilters] = useReducer(
    ordersFiltersReducer,
    ordersFiltersInitialState
  );

  const activeOrdersFiltersCount = useMemo(
    () =>
      Object.values(filters).reduce((count, value) => {
        if (Array.isArray(value)) {
          if (value.length) {
            ++count;
          }
        } else if (value) {
          ++count;
        }
        return count;
      }, 0),
    [filters]
  );

  return useMemo(
    () => ({
      ordersFilters: filters,
      changeOrdersFilters: dispatchFilters,
      activeOrdersFiltersCount,
    }),
    [activeOrdersFiltersCount, filters]
  );
}
