import { Option } from 'components/Select/Select';

export type OrdersFiltersState = {
  statuses: Option[];
  marinas: Option[];
  from?: Date;
  to?: Date;
};

export type OrdersFiltersAction =
  | {
      type:
        | OrdersFiltersActionTypes.STATUSES
        | OrdersFiltersActionTypes.MARINAS;
      payload: Option[];
    }
  | {
      type: OrdersFiltersActionTypes.FROM | OrdersFiltersActionTypes.TO;
      payload?: Date;
    }
  | { type: OrdersFiltersActionTypes.RESET_FILTERS };

export enum OrdersFiltersActionTypes {
  STATUSES = 'STATUSES',
  MARINAS = 'MARINAS',
  FROM = 'FROM',
  TO = 'TO',
  RESET_FILTERS = 'RESET_FILTERS',
}

export const ordersFiltersInitialState: OrdersFiltersState = {
  statuses: [],
  marinas: [],
  from: undefined,
  to: undefined,
};

export function ordersFiltersReducer(
  state: OrdersFiltersState,
  action: OrdersFiltersAction
): OrdersFiltersState {
  switch (action.type) {
    case OrdersFiltersActionTypes.STATUSES:
      return { ...state, statuses: action.payload };
    case OrdersFiltersActionTypes.MARINAS:
      return { ...state, marinas: action.payload };
    case OrdersFiltersActionTypes.FROM:
      return { ...state, from: action.payload };
    case OrdersFiltersActionTypes.TO:
      return { ...state, to: action.payload };
    case OrdersFiltersActionTypes.RESET_FILTERS:
      return ordersFiltersInitialState;
    default:
      return state;
  }
}
