import { MinMaxNumber } from 'types';
import {
  DISCOUNT_AMOUNT_MAX_VALUE,
  DISCOUNT_AMOUNT_MIN_VALUE,
  DISCOUNT_NAME,
} from 'utils/constants/filters';

export type DiscountsFiltersState = {
  discountName: string;
  discountAmount: MinMaxNumber;
  boatTitle: string;
};

export type DiscountsFiltersAction =
  | {
      type: DiscountsFiltersActionTypes.RESET_ALL;
    }
  | {
      type:
        | DiscountsFiltersActionTypes.UPDATE_DISCOUNT_NAME
        | DiscountsFiltersActionTypes.UPDATE_BOAT_TITLE;
      payload: string;
    }
  | {
      type: DiscountsFiltersActionTypes.UPDATE_DISCOUNT_AMOUNT;
      payload: MinMaxNumber;
    };

export enum DiscountsFiltersActionTypes {
  RESET_ALL = 'RESET_ALL',
  UPDATE_DISCOUNT_NAME = 'UPDATE_DISCOUNT_NAME',
  UPDATE_DISCOUNT_AMOUNT = 'UPDATE_DISCOUNT_AMOUNT',
  UPDATE_BOAT_TITLE = 'UPDATE_BOAT_TITLE',
}

export const initialDiscountsFilters: DiscountsFiltersState = {
  discountName: DISCOUNT_NAME,
  discountAmount: {
    min: DISCOUNT_AMOUNT_MIN_VALUE,
    max: DISCOUNT_AMOUNT_MAX_VALUE,
  },
  boatTitle: '',
};

export function discountsFiltersReducer(
  state: DiscountsFiltersState,
  action: DiscountsFiltersAction
): DiscountsFiltersState {
  switch (action.type) {
    case DiscountsFiltersActionTypes.RESET_ALL:
      return initialDiscountsFilters;
    case DiscountsFiltersActionTypes.UPDATE_DISCOUNT_NAME:
      return { ...state, discountName: action.payload };
    case DiscountsFiltersActionTypes.UPDATE_DISCOUNT_AMOUNT:
      return { ...state, discountAmount: action.payload };
    case DiscountsFiltersActionTypes.UPDATE_BOAT_TITLE:
      return { ...state, boatTitle: action.payload };
    default:
      return state;
  }
}
