import Button from 'components/Button/Button';
import { useCharter } from 'hooks';

export default function CharterChoiceList() {
  const { charters, charter: currentCharter, changeCharter } = useCharter();

  return (
    <div className="flex flex-col items-center justify-center px-5 pb-6">
      {charters &&
        charters.map((charter) => (
          <div key={charter.id} className="mt-2">
            <Button
              variant="link"
              ring={charter === currentCharter}
              onClick={() => changeCharter(charter)}
            >
              {charter.name}
            </Button>
          </div>
        ))}
    </div>
  );
}
