import { CharterBoatList } from 'api/fleet/fetchBoatsByCharterId';

export default function filterByMarinas(
  boats: CharterBoatList,
  marinas: string[]
) {
  if (!marinas.length) {
    return boats;
  }

  return boats.filter((boat) => marinas.includes(boat.marina_id));
}
