import { WeekdayIndex, WeekdayName } from 'types';

export const WEEKDAYS: Record<WeekdayName, WeekdayIndex> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
