import { CharterBoatList } from 'api/fleet/fetchBoatsByCharterId';
import useCharter from 'hooks/useCharter/useCharter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { BOATS_PER_PAGE } from 'utils/constants/calendar';

export default function useCalendarScroll(boats?: CharterBoatList) {
  const { charter } = useCharter();
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  const [displayedBoatsCount, setDisplayedBoatsCount] =
    useState(BOATS_PER_PAGE);
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    tableWrapperRef.current?.scrollTo(0, 0);

    setDisplayedBoatsCount(BOATS_PER_PAGE);

    /* Delays rendering of the sentry component.
       It might trigger 'loadMore' unintentionally if it is rendered sooner than table content. */
    const timeout = setTimeout(() => {
      setHasNextPage(!!boats?.length);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [charter, boats]);

  const loadMore = useCallback(() => {
    const boatsCount = boats?.length;

    if (boatsCount) {
      setDisplayedBoatsCount((value) => {
        const newValue = value + BOATS_PER_PAGE;
        if (newValue >= boatsCount) {
          setHasNextPage(false);
        }
        return newValue;
      });
    }
  }, [boats]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: false,
    hasNextPage,
    onLoadMore: loadMore,
    delayInMs: 150,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px',
  });

  const tableHeaderRef = useRef<HTMLDivElement>(null);

  return useMemo(
    () => ({
      displayedBoatsCount,
      tableHeaderRef,
      tableWrapperRef,
      scrollRootRef: rootRef,
      scrollSentryRef: sentryRef,
      hasNextPage: hasNextPage,
    }),
    [displayedBoatsCount, hasNextPage, rootRef, sentryRef]
  );
}
