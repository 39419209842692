import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth, useCharter } from 'hooks';
import { isDevelopment } from 'utils/environment';

const setDisableTracking = (trackingId: string, disable: boolean) => {
  (window as any)['ga-disable-' + trackingId] = disable;
};

export default function useTracking(
  trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID
) {
  const location = useLocation();
  const { user } = useAuth();
  const { charter, loading } = useCharter();

  // Save user/charter to ref, so useEffect for sending anylytics will be triggered only when location changes
  // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
  const userRef = useRef(user);
  const charterRef = useRef(charter);
  useEffect(() => {
    userRef.current = user;
    charterRef.current = charter;
  });

  useEffect(() => {
    if (!trackingId) {
      return;
    }

    if (!window.gtag) {
      return;
    }

    // Wait for the charter to be loaded before tracking
    // any page views to prevent missing data about charter
    if (loading) {
      return;
    }

    // Disable analytics for users with disabled logging
    if (userRef.current && !userRef.current.logging) {
      // disable automatic events triggered by Google Analytics (e.g. user_engagement)
      setDisableTracking(trackingId, true);
      return;
    } else {
      setDisableTracking(trackingId, false);
    }

    const config: Gtag.ConfigParams | Gtag.CustomParams = {
      send_page_view: false, // Don't send page view automatically, we send it manually after we set Charter name dimension
      custom_map: {
        dimension1: 'charter_name',
        dimension2: 'charter_id',
      },
    };

    if (isDevelopment()) {
      config.debug_mode = true;
    }

    config.charter_name = charterRef.current?.name;
    config.charter_id = charterRef.current?.id;
    config.user_id = userRef.current?.id || null;

    gtag('set', 'user_properties', {
      crm_id: userRef.current?.id || null,
    });

    gtag('config', trackingId, config);
    gtag('event', 'page_view');
  }, [location, loading, trackingId]);
}
