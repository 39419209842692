import { IconProps } from './IconProps';

export default function IconLocation(props: IconProps) {
  return (
    <svg
      width="64"
      height="69"
      viewBox="0 0 64 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.2929 66.707L32 67.4141L32.7071 66.707C36.2357 63.1784 41.4331 55.7055 45.7399 47.6134C47.8994 43.5559 49.8508 39.3134 51.2646 35.2979C52.6736 31.2959 53.5714 27.4564 53.5714 24.2245C53.5714 11.4721 43.9849 1 32 1C20.015 1 10.4286 11.4721 10.4286 24.2245C10.4286 27.4564 11.3263 31.2959 12.7353 35.2979C14.1491 39.3134 16.1005 43.5559 18.2601 47.6134C22.5668 55.7055 27.7643 63.1784 31.2929 66.707Z"
        fill="#1576d1"
        stroke="#fff"
        strokeWidth="2"
      />
      <circle cx="32" cy="22" r="10" fill="#fff" />
    </svg>
  );
}
