import { Option } from 'components/Select/Select';
import {
  BOAT_CABINS_MAX_VALUE,
  BOAT_CABINS_MIN_VALUE,
} from 'utils/constants/filters';

export type AvailabilityFiltersState = {
  boatTitle: string;
  boatMarinas: Option[];
  boatCabins: [number, number];
};

export type AvailabilityFiltersAction =
  | {
      type: AvailabilityFiltersActionTypes.RESET_ALL;
    }
  | {
      type: AvailabilityFiltersActionTypes.UPDATE_BOAT_TITLE;
      payload: string;
    }
  | {
      type: AvailabilityFiltersActionTypes.UPDATE_BOAT_MARINAS;
      payload: Option[];
    }
  | {
      type: AvailabilityFiltersActionTypes.UPDATE_BOAT_CABINS;
      payload: [number, number];
    };

export enum AvailabilityFiltersActionTypes {
  RESET_ALL = 'RESET_ALL',
  UPDATE_BOAT_TITLE = 'UPDATE_BOAT_TITLE',
  UPDATE_BOAT_MARINAS = 'UPDATE_BOAT_MARINAS',
  UPDATE_BOAT_CABINS = 'UPDATE_BOAT_CABINS',
}

export const initialAvailabilityFilters: AvailabilityFiltersState = {
  boatTitle: '',
  boatMarinas: [],
  boatCabins: [BOAT_CABINS_MIN_VALUE, BOAT_CABINS_MAX_VALUE],
};

export function availabilityFiltersReducer(
  state: AvailabilityFiltersState,
  action: AvailabilityFiltersAction
): AvailabilityFiltersState {
  switch (action.type) {
    case AvailabilityFiltersActionTypes.RESET_ALL:
      return initialAvailabilityFilters;
    case AvailabilityFiltersActionTypes.UPDATE_BOAT_TITLE:
      return { ...state, boatTitle: action.payload };
    case AvailabilityFiltersActionTypes.UPDATE_BOAT_MARINAS:
      return { ...state, boatMarinas: action.payload };
    case AvailabilityFiltersActionTypes.UPDATE_BOAT_CABINS:
      return { ...state, boatCabins: action.payload };
    default:
      return state;
  }
}
