import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Subset } from 'types';
import { BoatFormState } from 'types/fleet';

type FieldNames =
  | 'length'
  | 'beam'
  | 'draft'
  | 'year'
  | 'renovated_year'
  | 'sail_renovated_year'
  | 'engine_power'
  | 'number_engines'
  | 'total_engine_power'
  | 'engine_type'
  | 'fuel'
  | 'cruising_consumption'
  | 'maximum_speed'
  | 'water_tank'
  | 'waste_tank'
  | 'toilets'
  | 'electric_toilets'
  | 'single_cabins'
  | 'double_cabins'
  | 'triple_cabins'
  | 'quadruple_cabins'
  | 'cabins_with_bunk_bed'
  | 'saloon_sleeps'
  | 'crew_sleeps'
  | 'max_sleeps'
  | 'max_people';

export type ParamFieldNames = Subset<keyof BoatFormState, FieldNames>;

type ParamFieldLabels =
  | 'Length'
  | 'Beam'
  | 'Draft'
  | 'Year'
  | 'Refitted in'
  | 'Sail refitted in'
  | 'Fuel tank'
  | 'Fuel consumption'
  | 'Maximum speed'
  | 'Water tank'
  | 'Waste tank'
  | 'Single cabin'
  | 'Double cabin'
  | 'Triple cabin'
  | 'Quadruple cabin'
  | 'Double cabin (bunk beds)'
  | 'Beds in saloon'
  | 'Beds for the crew'
  | 'Maximum sleeps'
  | 'Maximum people'
  | 'Total toilets'
  | 'Electric toilets'
  | 'Engine power'
  | 'Number of engines'
  | 'Total engine power'
  | 'Engine type';

type FieldNameToLabelMap = Record<ParamFieldNames, ParamFieldLabels>;

export default function useFleetFormParametersLabels() {
  const { t } = useTranslation();
  return useMemo(() => {
    const labels: FieldNameToLabelMap = {
      length: t('Length'),
      beam: t('Beam'),
      draft: t('Draft'),
      year: t('Year'),
      renovated_year: t('Refitted in'),
      sail_renovated_year: t('Sail refitted in'),
      engine_power: t('Engine power'),
      number_engines: t('Number of engines'),
      total_engine_power: t('Total engine power'),
      engine_type: t('Engine type'),
      fuel: t('Fuel tank'),
      cruising_consumption: t('Fuel consumption'),
      maximum_speed: t('Maximum speed'),
      water_tank: t('Water tank'),
      waste_tank: t('Waste tank'),
      toilets: t('Total toilets'),
      electric_toilets: t('Electric toilets'),
      single_cabins: t('Single cabin'),
      double_cabins: t('Double cabin'),
      triple_cabins: t('Triple cabin'),
      quadruple_cabins: t('Quadruple cabin'),
      cabins_with_bunk_bed: t('Double cabin (bunk beds)'),
      saloon_sleeps: t('Beds in saloon'),
      crew_sleeps: t('Beds for the crew'),
      max_sleeps: t('Maximum sleeps'),
      max_people: t('Maximum people'),
    };
    return labels;
  }, [t]);
}
