import { IconProps } from './IconProps';

export default function IconPeople(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="42"
      viewBox="0 0 44 42"
      fill="currentColor"
      {...props}
    >
      <path d="M22.5 20a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Zm3.5-6.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" />
      <path
        fillRule="evenodd"
        d="M8.5 26a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm2.5-5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm19-1a5.5 5.5 0 1 0 11 0 5.5 5.5 0 0 0-11 0Zm5.5 2.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
        clipRule="evenodd"
      />
      <path d="M22 22c5.523 0 10 4.477 10 10h-3a7 7 0 1 0-14 0h-3c0-5.523 4.477-10 10-10Zm-11.169 5.515A8 8 0 0 0 0 35h3a5 5 0 0 1 7.17-4.506l.661-2.979ZM36 26c-.997 0-1.95.182-2.831.515l.661 2.979A5 5 0 0 1 41 34h3a8 8 0 0 0-8-8Z" />
    </svg>
  );
}
