import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { useAuth, useLocale, useSyncCharterIdUrl } from 'hooks';
import { ROUTE_CHARTER_LIST } from 'utils/constants/routes';
import extractCharterIdFromPath from 'utils/url/extractCharterIdFromPath';
import generateRoute from 'utils/url/generateRoute';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import LoadingContainer from 'components/layout/LoadingContainer/LoadingContainer';

// ! Temporary changes to enable Discount routes only for selected charters !
const ALLOWED_CHARTERS_FOR_DISCOUNTS = [
  '65cb93f38e361d2f650539af',
  '6380adfc5b3e2951100c4632',
  '5ab285e063654370461df450',
  '594a491ba7037954c16c6fcb',
  '596e113fa7037948587c66e1',
  '59521bfca7037936ce0aee1c',
  '5f58ca7d8cc6322825753f48',
  '6450cb503ff7f815890d4a1d',
  '6461e8c086cf93c2e80ffb44',
  '5e721512c86d8c1cc42bacb7',
];

export type Charter = {
  id: string;
  slug: string;
  name: string;
};

export type CharterUserState = {
  loading: boolean;
  charter: Charter | null;
  changeCharter: (charter: any) => void;
  charters: Charter[];
  discountsEnabled: boolean | null;
};

type CharterProviderProps = {
  children: ReactNode;
};

type CharterListResponse = {
  data: Charter[];
  count: number;
};

const CharterContext = createContext<CharterUserState | undefined>(undefined);

const CharterProvider = ({ children }: CharterProviderProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [charters, setCharters] = useState<Charter[]>([]);
  const [charter, setCharter] = useState<Charter | null>(null);
  const [discountsEnabled, setDiscountsEnabled] = useState<boolean | null>(
    null
  );
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { locale } = useLocale();

  const toggleDiscounts = useCallback((newCharterId: string) => {
    const enabled = ALLOWED_CHARTERS_FOR_DISCOUNTS.includes(newCharterId);
    setDiscountsEnabled(enabled);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      // Reset the state when user is logged out
      setLoading(false);
      setCharter(null);
      setCharters([]);

      return;
    }

    setLoading(true);

    axios
      .get<CharterListResponse>('/charters')
      .then((res) => {
        setCharters(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isAuthenticated]);

  useEffect(() => {
    if (charters.length && !charter) {
      // Set charter from url path
      const id = extractCharterIdFromPath(history.location.pathname);
      if (id) {
        const charterCandidate = charters.find((c) => c.id === id);
        if (charterCandidate) {
          setCharter(charterCandidate);
          toggleDiscounts(charterCandidate.id);
          return;
        }

        toast.error(
          t(
            'You are not authorised to access this link. Please contact your account manager.'
          )
        );
      }

      // invalid charter id or no charter selected -> redirect to choice list
      history.push(generateRoute(ROUTE_CHARTER_LIST, locale));
    }
  }, [charter, charters, history, locale, t, toggleDiscounts]);

  useSyncCharterIdUrl(charter);

  useEffect(() => {
    // Set first charter as default when there is only one charter
    if (charters.length === 1) {
      setCharter(charters[0]);
    }
  }, [charters]);

  const value = useMemo(
    () => ({
      loading,
      charters,
      charter,
      changeCharter: (newCharter: Charter | null) => {
        setCharter(newCharter);
        toggleDiscounts(newCharter?.id || '');
      },
      discountsEnabled,
    }),
    [loading, charters, charter, discountsEnabled, toggleDiscounts]
  );

  if (loading) {
    return <LoadingContainer />;
  }

  return (
    <CharterContext.Provider value={value}>{children}</CharterContext.Provider>
  );
};

export { CharterContext, CharterProvider };
