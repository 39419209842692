import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';

export default function setupDayjs() {
  dayjs.extend(utc);
  dayjs.extend(localizedFormat);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(isBetween);
}
