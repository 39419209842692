export const ACCOUNT_MANAGER = 'accountManager';

export const CONTRACT = 'contract';
export const CONTRACTS = 'contracts';
export const FIRST_CONTRACT = 'firstContract';

export const BOAT = 'boat';
/* Using both general and specific query key for identical endpoint used on different pages
   allows us to manage cached data independently or together (mutate, invalidate, etc.) */
export const BOATS = 'boats';
export const BOATS__AVAILABILITY = 'availabilityBoats';
export const BOATS__DISCOUNT = 'discountBoats';
export const BOATS__DISCOUNT_FORM = 'discountFormBoats';
export const BOAT_DRAFT = 'boatDraft';
export const BOAT_DRAFTS = 'boatDrafts';

export const FLEET_CHANGE_REQUEST = 'fleetChangeRequest';
export const FLEET_CHANGE_REQUESTS = 'fleetChangeRequests';
export const FLEET_PENDING_CHANGE_REQUESTS_COUNT =
  'fleetPendingChangeRequestsCount';

export const BASE = 'base';
export const BASES = 'bases';

export const BASE_CHANGE_REQUEST = 'baseChangeRequest';
export const BASE_CHANGE_REQUESTS = 'baseChangeRequests';
export const BASE_PENDING_CHANGE_REQUESTS_COUNT =
  'basePendingChangeRequestsCount';

export const CHANGE_REQUEST = 'changeRequest';
export const CHANGE_REQUESTS = 'changeRequests';

export const INVOICES = 'invoices';

export const ORDER = 'order';
export const ORDERS = 'orders';
export const ORDERS_PENDING_COUNT = 'ordersPendingCount';
export const CREW_LIST = 'crewList';

export const AVAILABILITIES = 'availabilities';

export const DISCOUNT = 'discount';
export const DISCOUNTS = 'discounts';

export const ORDERS_STATISTICS = 'ordersStatistics';
export const VIEWS_STATISTICS = 'viewsStatistics';
export const CUSTOMER_STATISTICS = 'customersStatistics';

export const BOAT_CATEGORIES = 'boatCategories';
export const BOAT_MANUFACTURERS = 'boatManufacturers';
export const BOAT_MODELS = 'boatModels';

export const COCKPIT_AMENITIES = 'cockpitAmenities';
export const ENTERTAINMENT_AMENITIES = 'entertainmentAmenities';
export const EQUIPMENT_AMENITIES = 'equipmentAmenities';

export const SAILS = 'sails';
export const PETS = 'pets';
export const CAPTAINS = 'captains';
export const LICENCES = 'licences';
export const ENGINE_TYPES = 'engineTypes';

export const COUNTRIES = 'countries';
export const POLICIES = 'policies';
export const SPECIALS = 'specials';
export const MARINAS = 'marinas';
