import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Charter } from 'contexts/CharterContext';
import extractCharterIdFromPath from 'utils/url/extractCharterIdFromPath';

/**
 * Sync URL pathname with current charter id if route uses charter id prefix
 *
 * Example:
 * /en/charter/route
 *     ^^^-------------- charter will be replaced by current id
 */
export default function useSyncCharterIdUrl(charter: Charter | null) {
  const history = useHistory();

  useEffect(() => {
    if (!charter) {
      return;
    }

    const { pathname } = history.location;
    const id = extractCharterIdFromPath(pathname);

    if (id && charter.id !== id) {
      const segments = pathname.split('/');
      // replace the id in the path by the current charter id
      segments[2] = charter?.id;

      // cut off remaining segments to redirect user to home page (dashboard)
      history.push(segments.slice(0, 3).join('/'));
    }
  }, [history, charter]);
}
