import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from 'react-query';
import createQueryClient from 'utils/createQueryClient';

import 'react-tabs/style/react-tabs.css';
import './index.scss';
import './i18n';

import App from './App';
import Error from './components/layout/Error/Error';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LocaleProvider } from 'contexts/LocaleContext';
import setupAxios from 'utils/setupAxios';
import setupDayjs from 'utils/setupDayjs';
import setupSentry from 'utils/setupSentry';
import { GlobalContextProvider } from 'contexts/GlobalContext';
import { AuthProvider } from 'contexts/AuthContext';
import { CharterProvider } from 'contexts/CharterContext';
import { isDevelopment } from 'utils/environment';

const isDev = isDevelopment();

if (!isDev) {
  setupSentry();
}
setupAxios();
setupDayjs();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={isDev ? <></> : <Error />}>
      <QueryClientProvider client={createQueryClient()}>
        <Suspense fallback={null}>
          <Router>
            <AuthProvider>
              <LocaleProvider>
                <CharterProvider>
                  <GlobalContextProvider>
                    <App />
                  </GlobalContextProvider>
                </CharterProvider>
              </LocaleProvider>
            </AuthProvider>
          </Router>
        </Suspense>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
