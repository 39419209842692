import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Sidebar.module.scss';
import logo from 'assets/logo.svg';
import PrimaryNavItem from 'components/layout/sidebar/PrimaryNavItem/PrimaryNavItem';
import SecondaryNavList from 'components/layout/sidebar/SecondaryNavList/SecondaryNavList';
import { Link } from 'components/routing';

import {
  ROUTE_AVAILABILITY,
  ROUTE_DISCOUNTS,
  ROUTE_BASES,
  ROUTE_CHANGE_REQUESTS,
  ROUTE_DASHBOARD,
  // ROUTE_DETAILS,
  ROUTE_FLEET,
  ROUTE_INVOICES,
  ROUTE_ORDERS,
} from 'utils/constants/routes';
import Button from 'components/Button/Button';
import CharterChoiceModal from 'components/modals/CharterChoiceModal/CharterChoiceModal';
import { useCharter, useSidebarCount } from 'hooks';
import { IconSwitch, IconX } from 'components/icons';

export type SidebarProps = {
  opened: boolean;
  onClose(): void;
};

export default function Sidebar({ opened, onClose }: SidebarProps) {
  const { charter, charters, discountsEnabled } = useCharter();

  const [charterChoiceModalOpened, setCharterChoiceModalOpened] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setCharterChoiceModalOpened(false);
  }, [charter]);

  useEffect(() => {
    ['overflow-hidden', 'h-screen'].forEach((className) => {
      [document.documentElement, document.body].forEach((element) =>
        element.classList[opened ? 'add' : 'remove'](className)
      );
    });
  }, [opened]);

  const counts = useSidebarCount();

  const navItems = [
    {
      name: t('Dashboard'),
      path: ROUTE_DASHBOARD,
      exact: true,
    },
    {
      name: t('Bookings'),
      path: ROUTE_ORDERS,
      countLabel: counts.ordersCount,
      exact: false,
    },
    {
      name: t('Availability'),
      path: ROUTE_AVAILABILITY,
      exact: false,
    },
    {
      name: t('Promotions'),
      path: ROUTE_DISCOUNTS,
      exact: false,
    },
    {
      name: t('Fleet'),
      path: ROUTE_FLEET,
      exact: false,
    },
    // {
    //   name: t('Charter company details'),
    //   path: ROUTE_DETAILS,
    //   exact: false,
    // },
    {
      name: t('Bases'),
      path: ROUTE_BASES,
      exact: false,
    },
    {
      name: t('Change requests'),
      path: ROUTE_CHANGE_REQUESTS,
      exact: false,
    },
    {
      name: t('Invoices'),
      path: ROUTE_INVOICES,
      exact: false,
    },
  ];

  // ! Temporary changes to enable Discount routes only for selected charters !
  const restrictedNavItems = navItems.filter(
    (item) =>
      item.path !== ROUTE_DISCOUNTS ||
      (item.path === ROUTE_DISCOUNTS && discountsEnabled)
  );

  return (
    <>
      <div
        className={classNames(
          { 'invisible opacity-0': !opened, 'opacity-50': opened },
          'absolute xl:hidden transition-opacity duration-500 inset-0 bg-gray-900 z-40'
        )}
        onClick={onClose}
      ></div>
      <section
        className={classNames(
          { [styles.opened]: opened },
          'absolute xl:relative right-0 xl:right-auto flex flex-col justify-between h-screen bg-white overflow-y-auto overflow-x-hidden transform-gpu translate-x-full xl:translate-x-0 transition xl:transition-none duration-300 ease-in-out z-50',
          styles.sidebar
        )}
      >
        <div className="flex flex-col pt-5 px-4">
          <div className="xl:hidden flex ml-auto text-gray-500">
            <Button size="small" showAsIcon onClick={onClose}>
              <IconX width={24} />
            </Button>
          </div>
          <Link to={ROUTE_DASHBOARD}>
            <img src={logo} alt="" className="w-auto h-[20px]" />
          </Link>
          <div className="mt-4">
            <div className="flex items-center text-gray-500 leading-tight">
              <span className="text-base mr-4">{charter?.name}</span>
              {charters.length > 1 && (
                <>
                  <Button
                    size="normal"
                    showAsIcon
                    onClick={() => setCharterChoiceModalOpened(true)}
                  >
                    <IconSwitch width={20} className="text-gray-400" />
                  </Button>
                  {charterChoiceModalOpened && (
                    <CharterChoiceModal
                      opened={charterChoiceModalOpened}
                      onClose={() => setCharterChoiceModalOpened(false)}
                    ></CharterChoiceModal>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <ul className="mt-8">
          {restrictedNavItems.map((item) => (
            <PrimaryNavItem
              key={item.path}
              name={item.name}
              to={item.path}
              countLabel={item.countLabel}
              exact={item.exact}
            />
          ))}
        </ul>
        <SecondaryNavList />
      </section>
    </>
  );
}
