import { QueryKey } from 'react-query';
import { useLocale } from 'hooks';
import { Locale } from 'i18n/types';

/**
 * Generates localized cache key for useUseQueryKey hook
 * When locale is changed, all localized queries are invalidated
 */
export default function useQueryKey(
  queryKey: QueryKey
): [Locale, ...unknown[]] {
  if (!Array.isArray(queryKey)) {
    queryKey = [queryKey];
  }

  const { locale } = useLocale();

  return [locale, ...queryKey];
}
