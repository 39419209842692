import { IconProps } from './IconProps';

export default function IconLocationRemove(props: IconProps) {
  return (
    <svg
      width="44"
      height="68"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 44 68"
      {...props}
    >
      <path
        fill="#fd7275"
        stroke="#fff"
        strokeWidth="2"
        d="m21.293 64.93.707.706.707-.707c3.433-3.433 8.488-10.701 12.676-18.57 2.1-3.945 3.998-8.071 5.373-11.977C42.126 30.49 43 26.754 43 23.607 43 11.196 33.67 1 22 1S1 11.196 1 23.607c0 3.147.874 6.883 2.244 10.775 1.375 3.906 3.273 8.032 5.373 11.977 4.188 7.869 9.242 15.137 12.676 18.57Z"
      />
      <circle cx="21.722" cy="21.445" r="9.722" fill="#fff" />
      <path fill="#fd7275" d="M28.611 22.125v-1.361H15v1.361h13.611Z" />
    </svg>
  );
}
