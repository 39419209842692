import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUrlSearchParams } from 'hooks';
import { useLocation } from 'react-router';

const PAGE_PARAM = 'page';

export default function usePagination(perPage = 20) {
  const { searchParams, setSearchParam } = useUrlSearchParams();
  const location = useLocation();

  const extractPageFromUrl = useCallback(
    () => Math.abs(Number(searchParams.get(PAGE_PARAM))) || 1,
    [searchParams]
  );

  const [page, setPageState] = useState(extractPageFromUrl);

  useEffect(() => {
    setPageState(extractPageFromUrl());
  }, [location, extractPageFromUrl]);

  const [itemsCount, setItemsCount] = useState(0);

  const pagesCount = useMemo(() => {
    return Math.ceil((itemsCount || 1) / perPage);
  }, [itemsCount, perPage]);

  const setPage = useCallback(
    (page: number) => {
      if (page > pagesCount) {
        page = pagesCount;
      }

      setPageState(page);
      setSearchParam(PAGE_PARAM, page.toString());
    },
    [pagesCount, setSearchParam]
  );

  const offset = (page - 1) * perPage;

  const value = useMemo(
    () => ({
      page,
      setPage,
      offset,
      pagesCount,
      setItemsCount,
    }),
    [page, setPage, offset, pagesCount, setItemsCount]
  );

  return value;
}
