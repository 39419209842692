import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import Button from 'components/Button/Button';
import { IconX } from 'components/icons';
import styles from './Modal.module.scss';
import { getEnvironment } from 'utils/environment';

export type ModalProps = ReactModal.Props & {
  size?: 'small' | 'normal' | 'large';
  fullHeight?: boolean;
  forcedBorders?: boolean;
  title?: string;
  footer?: ReactNode;
};

// Reset default inline styles, Tailwind classes will be used
ReactModal.defaultStyles = {};

export default function Modal({
  size = 'normal',
  fullHeight,
  forcedBorders,
  title,
  footer,
  children,
  ...props
}: ModalProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  // After component is rendered -> animations are trigged
  const [visible, setVisible] = useState(false);
  const [contentOverflows, setContentOverflows] = useState(false);

  useLayoutEffect(() => {
    // Push to back of event queue
    const timeout = setTimeout(() => {
      // Set/Reset visible (rendered) state based on isOpen
      // Reset must be done because component can be still rendered, only isOpen is false
      setVisible(props.isOpen);
    });

    return () => {
      setVisible(props.isOpen);
      clearInterval(timeout);
    };
  }, [props.isOpen]);

  const appElement = useMemo(
    () => document.getElementById('root') || undefined,
    []
  );

  // State is needed to watch if content overflows because ref doesn't trigger rerenders
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentOverflows(
        contentRef.current &&
          contentRef.current.scrollHeight > contentRef.current.clientHeight
      );
    }
  }, [footer, children]);

  const bordersDisplayed = contentOverflows || forcedBorders;

  return (
    <ReactModal
      {...props}
      shouldFocusAfterRender={props.shouldFocusAfterRender || false}
      ariaHideApp={getEnvironment() !== 'test'}
      appElement={appElement}
      preventScroll
      overlayClassName={classNames(
        'fixed top-0 flex items-end md:items-center justify-center w-screen h-full z-50 inset-0 bottom bg-gray-900',
        // Animations
        'md:bg-opacity-50 md:transition-none transition duration-200 ease-in-out',
        { 'bg-opacity-0': !visible },
        { 'bg-opacity-50': visible }
      )}
      className={classNames(
        'flex flex-col w-full md:w-11/12 max-h-[calc(100%-12px)] md:max-h-[90%] mx-auto',
        'bg-white rounded-b-none md:rounded-b-lg rounded-lg shadow-lg z-50 overflow-y-hidden',
        // Animations
        'md:translate-y-0 transition duration-300 ease-in-out',
        {
          'translate-y-full': !visible,
          'h-auto': !fullHeight,
          'h-[calc(100%-12px)]': fullHeight,
          'md:max-w-md': size === 'small',
          'md:max-w-lg': size === 'normal',
          'md:max-w-2xl': size === 'large',
        },
        styles.wrapper
      )}
    >
      <header
        className={classNames('flex justify-end px-3 md:px-4 pt-3 md:pt-4', {
          'justify-end pb-1 md:pb-3': !title,
          'justify-between items-start gap-x-5 pb-3 md:pb-4': title,
          'border-b border-gray-100': title && bordersDisplayed,
        })}
      >
        <div className="w-[40px] h-[40px] invisible" />

        {title && (
          <>
            <h3 className="hidden md:block self-center text-center">{title}</h3>
            <h4 className="md:hidden self-center text-center">{title}</h4>
          </>
        )}

        <Button
          size="small"
          showAsIcon
          onClick={props.onRequestClose || (() => {})}
        >
          <IconX width={24} />
        </Button>
      </header>

      <div
        ref={contentRef}
        className={classNames('h-full overflow-y-auto', {
          'py-3': bordersDisplayed,
        })}
      >
        {children}
      </div>

      {footer && (
        <footer
          className={classNames({
            'border-t border-gray-100': bordersDisplayed,
          })}
        >
          {footer}
        </footer>
      )}
    </ReactModal>
  );
}
